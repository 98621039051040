<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>巡查管理</el-breadcrumb-item>
        <el-breadcrumb-item>处罚方式列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt0">
      <div class="pt20">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="处罚方式名称"
              v-model="page.punish_name"
              clearable
              @clear="onSubmit(page.punish_name)"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="page.state"
              clearable
              placeholder="全部"
              @change="onSubmit(page.state)"
            >
              <el-option
                v-for="(item, index) in statusOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              type="date"
              clearable
              placeholder="巡查日期"
              v-model="page.patrol_time"
              :picker-options="pickerOptionsEnd"
              style="width: 100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="change(6, page.patrol_time)"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" size="medium"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="PunishAdd"
              >新增处罚方式</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search mt20">
       <el-form :inline="true" :model="page" class="demo-form-inline">
        <el-col :span="4" class="mr10">
            <el-form-item size="medium" >
         <el-input placeholder="处罚方式名称" v-model="page.punish_name" clearable class="input-with-select" @clear="onSubmit(page.punish_name)">
            </el-input>
        </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select v-model="page.state" clearable placeholder="全部" @change="onSubmit(page.state)">
                <el-option
                  v-for="(item,index) in statusOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
         <el-col :span="6" class="tl mr10">
           <el-form-item  size="medium">
          <el-button  type="primary"  @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
          <el-button type="primary" size="medium" @click="PunishAdd">新增处罚方式</el-button>
        </el-form-item>
        </el-col>
      </el-form>
    </el-row> -->
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%" id="out-table">
          <el-table-column
            type="index"
            align="center"
            width="60"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="punish_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="方式名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="user_name_add"
            min-width="120"
            :show-overflow-tooltip="true"
            label="添加人员"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="add_time"
            min-width="120"
            :show-overflow-tooltip="true"
            label="添加时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="user_name_update"
            min-width="100"
            :show-overflow-tooltip="true"
            label="更新人员"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="update_time"
            min-width="80"
            :show-overflow-tooltip="true"
            label="更新时间"
            align="center"
          ></el-table-column>

          <el-table-column
            align="center"
            label="状态"
            min-width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span
                class="f12"
                :class="scope.row.state === 1 ? 'c67c' : 'ce6a'"
                >{{ scope.row.state | statusFilter }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="goUpdate(scope.row.punish_id)"
                >编辑</el-button
              >
              <el-button
                type="text"
                class="disib"
                :class="{ red: scope.row.state == 1 }"
                size="mini"
                @click="changeState(scope.row)"
                >{{ scope.row.state | antiStateFilter }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { session } from "../../../util/util";
import { mapState } from "vuex";
export default {
  data() {
    return {
      page: {
        pager_offset: "0",
        pager_openset: "10",
        punish_name: "",
        state: "",
      },
      timer: "",
      loading: false,
      patrolOptions: [
        { label: "全部", value: "" },
        { label: "巡查工单", value: "1" },
        { label: "双随机工单", value: "2" },
        { label: "派发工单", value: "3" },
      ],
      statusOptions: [
        { label: "全部", value: "" },
        { label: "已启用", value: "1" },
        { label: "已禁用", value: "2" },
      ],
      currentPage: 1,
      total: 0,
      tableData: [],
      activeName: "1",
      pickerOptionsEnd: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: "已启用",
        2: "已禁用",
      };
      return statusMap[status];
    },
    antiStateFilter(state) {
      const stateMap = {
        1: "禁用",
        2: "启用",
      };
      return stateMap[state];
    },
  },
  computed: {
    ...mapState({
      punishPage: (state) => state.patrol.punishPage,
    }),
  },
  created() {
    this.page = Object.assign({}, this.punishPage);
    this.currentPage = this.page.pager_offset / 10 + 1;
    // this.page.patrol_time = ''
    // this.page.patrol_type = ''
    this.init(this.page);
  },
  methods: {
    init(params) {
      axios.get("/pc/patrol-punish/list", params).then((v) => {
        this.tableData = v.data.patrol_punish_list;
        this.total = v.data.pager_count;
      });
    },
    // 搜索
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.$store.dispatch("patrol/setNewPage2", this.page);
      this.init(this.page);
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.$store.dispatch("patrol/setNewPage2", this.page);
      this.init(this.page);
    },
    //新增处罚方式
    PunishAdd() {
      session.set("params", { id: "", name: "" });
      this.$router.push({ name: "PunishAdd" });
    },
    //编辑
    goUpdate(id) {
      session.set("params", { id: id, name: "" });
      this.$router.push({ name: "PunishAdd" });
    },
    //更改状态
    changeState(row) {
      let { punish_id, state } = row;
      state = state == 1 ? 2 : 1;
      axios
        .put("/pc/patrol-punish/state/update", { punish_id, state })
        .then((response) => {
          this.init(this.page);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>
